import React, { useState } from 'react';

const Registration = () => {
    // State for form fields
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        // Prepare data for the request
        const userData = {
            username,
            email,
            password,
        };

        try {
            // Make a POST request to the Flask API's /register endpoint
            const response = await fetch('/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            
            // Show message based on response
            if (response.ok) {
                setMessage('Registration successful! You can now login.');
            } else {
                setMessage(`Error: ${data.error}`);
            }
        } catch (error) {
            setMessage('Something went wrong. Please try again.');
        }
    };

    return (
        <div className="login-form">
            <table>
                <thead>
                    <tr>
                        <th colSpan="2">Register to LinkCloud</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <label htmlFor="username">Username:</label>
                        </td>
                        <td>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor="email">Email:</label>
                        </td>
                        <td>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor="password">Password:</label>
                        </td>
                        <td>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <input type="submit" value="Register" onClick={handleSubmit} />
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>{message}</p> {/* Display message */}
        </div>
    );
};

export default Registration;
