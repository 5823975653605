import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link for navigation
import '../css/Topbar.css'; // Import the CSS file for styling
import { AuthContext } from "../context/AuthContext"; // Import the AuthContext

const Topbar = () => {
    const navigate = useNavigate(); // Initialize the navigate function from React Router
    const { authToken, updateAuthToken } = useContext(AuthContext); // Access authToken and updateAuthToken from AuthContext

    // Handle login/logout button state
    const handleAuthAction = () => {
        if (authToken) {
            logout();
        } else {
            redirectToLogin();
        }
    };

    const redirectToLogin = () => {
        navigate("/login"); // Use navigate for routing
    };

    const logout = async () => {
        try {
            const response = await fetch("/api/logout", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                updateAuthToken(null); // Clear the auth token globally
                navigate("/login"); // Redirect to login after logout
            } else {
                alert("Logout failed. Please try again.");
            }
        } catch (error) {
            console.error("Error during logout:", error);
            alert("An error occurred while logging out.");
        }
    };
    
    return (
        <div className="topbar">
            <Link to="/" className="title-name">LinkBMS Cloud</Link>
            <div className="topbar-buttons">
                <div className="topbar-button" onClick={handleAuthAction}>{authToken ? "Logout" : "Login"}</div>
            </div>
        </div>
    );
};

export default Topbar;
