import React from 'react';
import Topbar from './components/Topbar';
import './css/Layout.css'; // Optional: You can have additional styles for your layout

const Layout = ({ children }) => {
    return (
        <div className="layout">
            <Topbar />
            <main className="main-content">
                {children}
            </main>
        </div>
    );
};

export default Layout;
