import React, { useEffect, useState , useRef } from "react";
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Ensure you have this for date handling in charts

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [userDevices, setUserDevices] = useState({});
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [deviceDescription, setDeviceDescription] = useState(""); // New state for device description
  const [isPublic, setIsPublic] = useState(false); // New state for 'public' field
  const [error, setError] = useState("");
  const [file, setFile] = useState(null); // New state for the file
  const [deviceIdHex, setDeviceIdHex] = useState(""); 

  const [data, setData] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedData, setSelectedData] = useState(['download_speed', 'upload_speed', 'ping']); // Adjust based on your needs
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const [description, setDescription] = useState(""); // New state for description
  const [files, setFiles] = useState([]); // New state for storing files
  const [selectedFileId, setSelectedFileId] = useState(""); // New state for selected file

  const [responseMessage, setResponseMessage] = useState('');

  const sendCommand = async (deviceId, command, value) => {
    try {
      const response = await fetch('/api/commandadmin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_id: deviceId,
          command: command,
          value: value,
        }),
      });

      const result = await response.json();
      if (result.success) {
        setResponseMessage(result.message);
      } else {
        setResponseMessage('Failed to send command');
      }
    } catch (error) {
      console.error('Error sending command:', error);
      setResponseMessage('Error sending command');
    }
  };
  // Fetch all users and devices initially
  const fetchUsersAndDevices = async () => {
    try {
      const response = await fetch("/api/get_users_and_devices", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setUsers(data.users);
        setDevices(data.devices);
        setUserDevices(data.user_devices);
      } else {
        setError(data.error || "Failed to fetch data");
      }

      // Fetch files separately
      const filesResponse = await fetch("/api/files_list", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const filesData = await filesResponse.json();
      if (filesResponse.ok) {
        setFiles(filesData);
      } else {
        setError(filesData.error || "Failed to fetch files");
      }

    } catch (err) {
      setError("Error fetching data: " + err.message);
    }
  };

  const fetchServerData = async () => {
    try {

      const pingresponse = await fetch(`/api/admin/data?start_time=${localToUTC(startTime)}&end_time=${localToUTC(endTime)}`);
      if (!pingresponse.ok) throw new Error('Failed to fetch data');
      const result = await pingresponse.json();
      setData(result);
      renderChart(result); // Call renderChart after fetching data


    } catch (err) {
      setError("Error fetching data: " + err.message);
    }
  };

  const utcToLocal = (date) => {
    const date2 = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
    return date2;
  };

  const localToUTC = (localDateString) => {
    return new Date(localDateString).toISOString().slice(0, 16);
  };

  useEffect(() => {
    // Fetch data whenever startTime or endTime changes
    
    if (startTime && endTime) {
        fetchServerData();
    }
  }, [startTime, endTime]);

  const getLastDayDateRange = () => {
    const end = new Date();
    const start = new Date(end);
    start.setDate(start.getDate() - 1); // Set to 1 day before end

    return { start, end };
  };
  
  const renderChart = (data) => {
    const ctx = chartRef.current.getContext("2d");
    if (chartInstance) {
      chartInstance.destroy(); // Destroy previous instance
    }

    const hasData = data && data.length > 0;

    const chartData = {
      labels: hasData ? data.map((d) => new Date(d.test_time)) : [],
      datasets: [],
    };

    let yAxes = [];

    // If no data is selected, show a message or empty dataset
    if (selectedData.length === 0) {
      chartData.datasets.push({
        label: "No Data Selected",
        data: [0, 0, 0, 0], // Dummy data for showing an empty graph
        borderColor: "gray",
        fill: false,
        pointRadius: 0,
      });

      yAxes = [
        {
          id: "y-axis-0",
          type: "linear",
          position: "left",
          beginAtZero: true,
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "No Data",
          },
        },
      ];
    } else {
      const yAxisGroups = [];

      const findYAxisGroup = (amplitude) => {
        for (let i = 0; i < yAxisGroups.length; i++) {
          const groupAmplitude = yAxisGroups[i].amplitude;
          if (
            amplitude.max <= groupAmplitude.max * 2 &&
            amplitude.min >= groupAmplitude.min / 2
          ) {
            return i; // Return the group index where the dataset fits
          }
        }
        return -1; // No matching group found
      };

      selectedData.forEach((field, index) => {
        const dataForField = data.map((d) => ({
          x: new Date(d.test_time),
          y: d[field],
        }));

        const values = data.map((d) => d[field]);
        const amplitude = {
          min: Math.min(...values),
          max: Math.max(...values),
        };

        let groupIndex = findYAxisGroup(amplitude);
        if (groupIndex === -1) {
          groupIndex = yAxisGroups.length;
          yAxisGroups.push({
            amplitude,
            fields: [field],
          });
        } else {
          yAxisGroups[groupIndex].fields.push(field);
        }

        chartData.datasets.push({
          label: field,
          data: dataForField,
          borderColor: getColor(index),
          fill: true,
          backgroundColor: getColor(index, "20%"),
          pointRadius: 0,
          borderWidth: 2,
          yAxisID: `y-axis-${groupIndex}`,
          spanGaps: false,
        });
      });

      yAxes = yAxisGroups.map((group, index) => ({
        id: `y-axis-${index}`,
        type: "linear",
        position: index % 2 === 0 ? "left" : "right",
        beginAtZero: true,
        grid: {
          drawOnChartArea: index % 2 === 0,
        },
        title: {
          display: true,
          text: group.fields.join(", "),
        },
      }));
    }

    const newChartInstance = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: {
        animation: false,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "hour",
              tooltipFormat: "yyyy-MM-dd HH:mm",
              displayFormats: {
                hour: "HH:mm",
                day: "yyyy-MM-dd",
              },
            },
          },
          y: yAxes,
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
      },
    });

    setChartInstance(newChartInstance);
  };

  // Handle form submission for adding a new device to a user
  const handleAddDevice = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/add_device", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          user_id: selectedUserId,
          device_id: selectedDeviceId,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Device added successfully to user.");
        fetchUsersAndDevices(); // Refresh data
        setSelectedDeviceId("");
      } else {
        alert("Error: " + data.error);
      }
    } catch (err) {
      alert("Error adding device: " + err.message);
    }
  };

  // Handle form submission for removing a device from a user
  const handleRemoveDevice = async (deviceId) => {
    try {
      const response = await fetch("/api/remove_device", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ user_id: selectedUserId, device_id: deviceId }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Device removed successfully from user.");
        fetchUsersAndDevices(); // Refresh data
      } else {
        alert("Error: " + data.error);
      }
    } catch (err) {
      alert("Error removing device: " + err.message);
    }
  };

  // Handle updating the device description and public status
  const handleUpdateDevice = async () => {
    if (!selectedDeviceId) {
      alert("Please select a device to update.");
      return;
    }

    try {
      const response = await fetch("/api/update_device", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          device_id: selectedDeviceId,
          description: deviceDescription,
          public: isPublic,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Device updated successfully.");
        fetchUsersAndDevices(); // Refresh data
      } else {
        alert("Error: " + data.error);
      }
    } catch (err) {
      alert("Error updating device: " + err.message);
    }
  };

  // When a user is selected, fetch their devices
  const handleUserSelect = (e) => {
    setSelectedUserId(e.target.value);
  };

  // Handle device selection and load the selected device's details
  const handleDeviceSelect = (e) => {
    const deviceId = e.target.value;
    setSelectedDeviceId(deviceId);

    const selectedDevice = devices.find((device) => String(device[0]) === String(deviceId));
    if (selectedDevice) {

    setDeviceIdHex(selectedDevice[1]); // Assuming description is in the third field
      setDeviceDescription(selectedDevice[2]); // Assuming description is in the third field
      setIsPublic(selectedDevice[3]); // Assuming public status is in the fourth field
    }
  };

    const handleDelete = async () => {
        try {
            const response = await fetch(
            `api/device/delete_history?device_id=${selectedDeviceId}`,
            {
                method: "POST",
                headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json"
                }
            }
            );

            const data = await response.json();
            if (response.ok) {
            alert("History deleted");
            } else {
            alert("Error: " + data.error);
            }
        } catch (err) {
            alert("An error occurred while deleting the device data: " + err.message);
        }
    };

  const handleFileDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleUploadFile = async (event) => {
    event.preventDefault();

    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Append file
    formData.append("description", description); // Append description

    try {
      const response = await fetch("/api/upload_file", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token for authorization
        },
        body: formData, // Send form data (file + description)
      });

      const data = await response.json();
      if (response.ok) {
        alert("File uploaded successfully.");
      } else {
        alert("Error uploading file: " + data.error);
      }
    } catch (err) {
      alert("Error uploading file: " + err.message);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  // Handle description change
  const handleDescriptionChange = (e) => {
    setDeviceDescription(e.target.value);
  };

  // Handle public checkbox change
  const handlePublicChange = (e) => {
    setIsPublic(e.target.checked);
  };

  const handleUpdateFilesystem = async () => {
    if (!selectedFileId) {
      alert("Please select a file to generate a download link.");
      return;
    }

    try {
      const response = await fetch(
        `/api/updatefs/${selectedDeviceId}/${selectedFileId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        alert("UpdateFS sent");
      } else {
        alert("Error: " + data.error);
      }
    } catch (err) {
      alert("Error generating download link: " + err.message);
    }
  };

  const handleUpdateFirmware = async () => {
    if (!selectedFileId) {
      alert("Please select a file to generate a download link.");
      return;
    }

    try {
      const response = await fetch(
        `/api/update/${selectedDeviceId}/${selectedFileId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        alert("Update firmware sent");
      } else {
        alert("Error: " + data.error);
      }
    } catch (err) {
      alert("Error generating download link: " + err.message);
    }
  };

  // Handle file selection
  const handleFileSelect = (e) => {
    setSelectedFileId(e.target.value);
  };

  // Initial fetch of users and devices on component mount
  useEffect(() => {
    const now = new Date();
    const startTime = new Date(now);
    startTime.setDate(startTime.getDate() - 1);
    setStartTime(utcToLocal(startTime));
    setEndTime(utcToLocal(now));
    fetchUsersAndDevices();
  }, []);


  // Helper function to generate color based on index
const getColor = (index, alpha = "100%") => {
    const colors = [
      `rgba(75, 192, 192, ${alpha})`,
      `rgba(255, 99, 132, ${alpha})`,
      `rgba(255, 206, 86, ${alpha})`,
      `rgba(54, 162, 235, ${alpha})`,
      `rgba(255, 159, 64, ${alpha})`,
    ];
    return colors[index % colors.length];
  };


  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>

      {/* Form to select a user and device */}
      <form id="selectUserDeviceForm" onSubmit={handleAddDevice}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="userSelect">Select User:</label>
              </td>
              <td>
                <select
                  id="userSelect"
                  name="user_id"
                  value={selectedUserId}
                  onChange={handleUserSelect}
                  required
                >
                  <option value="" disabled>
                    Select a user
                  </option>
                  {users.map((user) => (
                    <option key={user[0]} value={user[0]}>
                      {`${user[1]} (${user[2]})`}
                    </option>
                  ))}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <label htmlFor="deviceSelect">Select Device:</label>
              </td>
              <td>
                <select
                  id="deviceSelect"
                  name="device_id"
                  value={selectedDeviceId}
                  onChange={handleDeviceSelect}
                  required
                >
                  <option value="" disabled>
                    Select a device
                  </option>
                  {devices.map((device) => (
                    <option key={device[0]} value={device[0]}>
                      {`${device[1]} (${device[2]})`}
                    </option>
                  ))}
                </select>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <input type="submit" value="Add Device to User" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      {/* Form to update the selected device's description and public status */}
      {selectedDeviceId && (
        <div>
          <h2>Edit Selected Device</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateDevice();
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="deviceDescription">
                      Device Description:
                    </label>
                  </td>
                  <td>
                    <input
                      id="deviceDescription"
                      type="text"
                      value={deviceDescription}
                      onChange={handleDescriptionChange}
                      placeholder="New Description"
                      required
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label htmlFor="publicCheckbox">Public:</label>
                  </td>
                  <td>
                    <input
                      id="publicCheckbox"
                      type="checkbox"
                      checked={isPublic}
                      onChange={handlePublicChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <button type="submit">Update Device</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        <div>
            <button onClick={() => sendCommand(deviceIdHex, 'ZeroReset', '1')}>Zero reset + cycle counter</button>
            <button onClick={() => sendCommand(deviceIdHex, 'Restart', '1')}>Restart GUI</button>
            <button onClick={() => sendCommand(deviceIdHex, 'RestartSTM', '1')}>Restart BMS</button>
            <button onClick={() => sendCommand(deviceIdHex, 'ForceCharge', '1')}>ForceCharge</button>
            <button onClick={() => sendCommand(deviceIdHex, 'LemLow', '1')}>Calibrate LEM 0A</button>
        </div>
            {responseMessage && <p>{responseMessage}</p>}
        </div>
      )}

      {/* Display the selected user's devices */}
      {selectedUserId && (
        <div>
          <h2>{`Devices for Selected User`}</h2>
          {userDevices[selectedUserId]?.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Device Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userDevices[selectedUserId].map((deviceId) => {
                  const device = devices.find((dev) => dev[0] === deviceId);
                  return (
                    <tr key={deviceId}>
                      <td>{device ? device[1] : `Device ID: ${deviceId}`}</td>
                      <td>{device ? device[2] : "N/A"}</td>
                      <td>
                        <button onClick={() => handleRemoveDevice(deviceId)}>
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No devices found for this user.</p>
          )}
        </div>
      )}

      <h2>Update Device</h2>
      {/* File Upload Form */}
      <form id="uploadFileForm" onSubmit={handleUploadFile}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="file">Upload File:</label>
              </td>
              <td>
                <input
                  id="file"
                  type="file"
                  accept=".bin" // Specify allowed file types
                  onChange={handleFileChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="description">Description:</label>
              </td>
              <td>
                <input
                  id="description"
                  type="text"
                  value={description}
                  onChange={handleFileDescriptionChange}
                  placeholder="Enter file description"
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input type="submit" value="Upload File" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      {/* File selection and download link generation */}
      <div>
        <form>
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="fileSelect">Select File:</label>
                </td>
                <td colSpan="2">
                  <select
                    id="fileSelect"
                    name="file_id"
                    value={selectedFileId}
                    onChange={handleFileSelect}
                    required
                  >
                    <option value="" disabled>
                      Select a file
                    </option>
                    {files.map((file) => (
                      <option key={file.id} value={file.id}>
                        {`${file.filename} - ${file.description}`}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              <tr>
                <td>
                  <button type="button" onClick={handleUpdateFilesystem}>
                    Update filesystem
                  </button>
                </td>
                <td>
                  <button type="button" onClick={handleUpdateFirmware}>
                    Update firmware
                  </button>
                </td>

                <td>
                  <button type="button" onClick={handleDelete}>
                    Delete History
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}

      <div>
      <h2>Speed Test Data</h2>
      <input
        type="datetime-local"
        value={startTime}
        onChange={(e) => setStartTime(e.target.value)}
      />
      <input
        type="datetime-local"
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
      />
      <canvas className="chart-container" ref={chartRef}></canvas>
    </div>



    </div>
  );
};

export default Admin;
