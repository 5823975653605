import React from "react";
import "./css/App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import DeviceList from "./components/DeviceList";
import DeviceData from "./components/DeviceData";
import DeviceDetail from "./components/DeviceDetail";
import LoginForm from "./components/LoginForm";
import Registration from "./components/Register";
import DeviceHistory from "./components/DeviceHistory";
import DeviceEvents from "./components/DeviceEvents";
import Admin from "./components/Admin";


function App() {
  return (
    <Router>
      <Layout>
        <div className="App">
          <Routes>
            <Route path="/" element={<DeviceList />} /> {/* Home Page */}
            <Route path="/admin" element={<Admin />} /> {/* Login Page */}
            <Route path="/login" element={<LoginForm />} /> {/* Login Page */}
            <Route path="/register" element={<Registration />} />
            <Route path="/device/history" element={<DeviceHistory />} />{" "}
            <Route path="/device/data" element={<DeviceData />} />{" "}
            <Route path="/device/detail" element={<DeviceDetail />} />{" "}
            <Route path="/device/events" element={<DeviceEvents />} />{" "}
          </Routes>
        </div>
      </Layout>
    </Router>
  );
}

export default App;
