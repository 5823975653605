import React, { useContext, useState } from "react"; // Add useContext here
import { useNavigate } from "react-router-dom"; // to handle redirection
import { AuthContext } from "../context/AuthContext";

export default function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // React Router hook for navigation
  const { updateAuthToken } = useContext(AuthContext); // Access the token update function

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Sending login request to the server
    try {
      const response = await fetch("/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        updateAuthToken("token"); // Store the token globally
        navigate("/"); // Redirect to homepage after successful login
      } else {
        setErrorMessage(data.error); // Set error message
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}{" "}
        <table>
          <thead>
            <tr></tr>
            <th colSpan="2">Login to LinkCloud</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <label htmlFor="username">Username:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </td>
            </tr>

            <tr>
              <td>
                <label htmlFor="password">Password:</label>
              </td>
              <td>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <a href="/register">Register</a>
        <br />
        <br />
        <input type="submit" value="Login" />
      </form>
    </div>
  );
}
