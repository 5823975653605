import React, { useEffect, useRef, useState } from "react";
import "../css/DeviceInfo.css";
import DeviceLayout from "./DeviceLayout";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-date-fns";

Chart.register(...registerables);

const DeviceData = () => {
  const [jsonData, setJsonData] = useState(null);
  const [historyData, setHistoryData] = useState(null);

  const firstChartRef = useRef(null); // First chart reference
  const secondChartRef = useRef(null); // Second chart reference
  const [firstChartInstance, setFirstChartInstance] = useState(null);
  const [secondChartInstance, setSecondChartInstance] = useState(null);

  const localToUTC = (localDateString) => {
    return new Date(localDateString).toISOString().slice(0, 16);
  };

  const fetchData = async () => {
    const id = getDeviceIdFromUrl(); // Get device ID from URL
    if (id) {
      try {
        const response = await fetch(`/api/device/${id}`);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error("Error fetching device data:", error);
      }

      try {
        const now = new Date();
        const startTime = new Date(now);
        startTime.setDate(startTime.getDate() - 1);
        const url = `/api/device/data?device_id=${id}&fields=SOC,PowerAct&start_time=${localToUTC(
          startTime
        )}&end_time=${localToUTC(now)}`;
        console.log(url);
        const response = await fetch(url);
        const data2 = await response.json();
        setHistoryData(data2);
      } catch (error) {
        console.error("Error fetching device data:", error);
      }

    } else {
      console.error("Device ID not found in the URL.");
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  const renderChart = (data, chartRef, parameter, yAxisLabel, zeroBegin, color) => {
    const ctx = chartRef.current.getContext("2d");

    const hasData = data && data.length > 0;

    const chartData = {
      labels: hasData ? data.map((d) => new Date(d.received_at)) : [],
      datasets: []
    };

    if (hasData) {
      const filteredData = data.map((d) => ({
        x: new Date(d.received_at),
        y: d[parameter], // Use the selected parameter (SOC or Voltage)
      }));

      chartData.datasets.push({
        data: filteredData,
        borderColor: "#aaa",
        fill: true,
        backgroundColor: `hsl(${color}, 70%, 50%, 50%)`, // Update to gradient if needed
        pointRadius: 0,
        borderWidth: 1,
      });
    }

    const yAxes = {
      beginAtZero: zeroBegin,
      suggestedMin: 0,
      max: parameter === "SOC" ? 100 : undefined, // Set to 0-100% range for SOC
      grid: {
        drawOnChartArea: false, // Disable vertical grid lines
      },
      title: {
        display: true,
        text: yAxisLabel,
      },
    };

    return new Chart(ctx, {
      type: "line",
      data: chartData,
      options: {
        animation: false,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "hour",
              tooltipFormat: "yyyy-MM-dd HH:mm",
              displayFormats: {
                hour: "HH:mm",
                day: "yyyy-MM-dd",
              },
            },
            ticks: {
              maxTicksLimit: 24, // Limit the number of tick marks to 8
            },
          },
          
          y: yAxes,
        },
        plugins: {
          legend: {
            display: false, // Remove legend
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
      },
    });
  };

  const getDeviceIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  };

  const updateTable = () => {
    if (!jsonData) return;

    // Update state instead of using getElementById
    setPackTotalVoltage(jsonData.PackTotalVoltage * 1);
    //setCellAvgVoltage((jsonData.PackTotalVoltage / jsonData.CellCount).toFixed(3));
    setSOC(jsonData.SOC * 1);
    setCurrentAct(jsonData.CurrentAct * 1);
    setCellMinVoltage(jsonData.CellMinVoltage);
    setCellMaxVoltage(jsonData.CellMaxVoltage);
    setCellDiff((jsonData.CellMaxVoltage - jsonData.CellMinVoltage).toFixed(3));
    setCellMaxTemp(jsonData.CellMaxTemp * 1);
  };

  // State variables for device parameters
  const [packTotalVoltage, setPackTotalVoltage] = useState("");
  //const [cellAvgVoltage, setCellAvgVoltage] = useState("");
  const [SOC, setSOC] = useState("");
  const [currentAct, setCurrentAct] = useState("");
  const [cellMinVoltage, setCellMinVoltage] = useState("");
  const [cellMaxVoltage, setCellMaxVoltage] = useState("");
  const [cellDiff, setCellDiff] = useState("");
  const [cellMaxTemp, setCellMaxTemp] = useState("");

  useEffect(() => {
    updateTable();
  }, [jsonData]);

  useEffect(() => {
    // Clean up previous charts if they exist
    if (firstChartInstance) {
      firstChartInstance.destroy();
    }
    if (secondChartInstance) {
      secondChartInstance.destroy();
    }

    // Render the first chart for SOC
    if (historyData && firstChartRef.current) {
      const newFirstChartInstance = renderChart(
        historyData, 
        firstChartRef, 
        "SOC", 
        "SOC (%)",
        true,
        0
      );
      setFirstChartInstance(newFirstChartInstance);
    }

    // Render the second chart for Voltage
    if (historyData && secondChartRef.current) {
      const newSecondChartInstance = renderChart(
        historyData, 
        secondChartRef, 
        "PowerAct", 
        "Power (W)",
        false,
        0
      );
      setSecondChartInstance(newSecondChartInstance);
    }

    // Ensure both charts are destroyed when the component unmounts
    return () => {
      if (firstChartInstance) {
        firstChartInstance.destroy();
      }
      if (secondChartInstance) {
        secondChartInstance.destroy();
      }
    };
  }, [historyData]);  // Only listen for changes to historyData


  return (
    <DeviceLayout>
      <div>
        <div id="chart-container2">
          <canvas ref={firstChartRef} id="first-chart" className="chart-container2"></canvas>
        </div>
        <div id="chart-container2">
          <canvas ref={secondChartRef} id="second-chart" className="chart-container2"></canvas>
        </div>
      </div>

      <div className="DeviceData">
        <div className="kloc">
          SOC
          <div>{SOC} %</div> {/* Render SOC from state */}
        </div>
        <div className="kloc">
          Voltage
          <div>{packTotalVoltage} V</div> {/* Render PackTotalVoltage from state */}
        </div>
        <div className="kloc">
          Cell Min V
          <div>{cellMinVoltage} V</div> {/* Render CellMinVoltage from state */}
        </div>
        <div className="kloc">
          Cell Max V
          <div>{cellMaxVoltage} V</div> {/* Render CellMaxVoltage from state */}
        </div>
        {/*
        <div className="kloc">
          Cell Avg V
          <div>{cellAvgVoltage} V</div>
        </div>
        */}
        <div className="kloc">
          Cell Diff
          <div>{cellDiff} V</div> {/* Render Cell Diff from state */}
        </div>
        <div className="kloc">
          Current
          <div>{currentAct} A</div> {/* Render CurrentAct from state */}
        </div>
        <div className="kloc">
          Temperature
          <div>{cellMaxTemp} °C</div> {/* Render CellMaxTemp from state */}
        </div>
      </div>
    </DeviceLayout>
  );
};

export default DeviceData;
